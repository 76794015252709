<div class="logo"> 
  <!-- <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="364.000000pt" height="122.000000pt" viewBox="0 0 364.000000 122.000000" preserveAspectRatio="xMidYMid meet"> 
    <g transform="translate(0.000000,122.000000) scale(0.100000,-0.100000)" stroke="none">
      <path d="M1744 1199 c-216 -27 -411 -184 -484 -392 -79 -224 -25 -460 146 -631 110 -111 254 -169 419 -169 161 0 298 58 414 174 236 236 237 605 2 847 -122 125 -318 193 -497 171z m273 -204 c254 -125 320 -465 132 -679 -136 -154 -339 -193 -525 -101 -168 83 -269 278 -236 457 55 298 360 455 629 323z"/>
      <path d="M1713 986 c-105 -34 -204 -123 -251 -224 -24 -50 -27 -69 -27 -157 0 -91 3 -106 31 -165 39 -83 111 -155 194 -194 58 -28 75 -31 160 -31 86 0 101 3 162 32 84 40 151 107 192 193 28 59 31 74 31 165 0 91 -3 106 -31 165 -38 81 -108 151 -190 191 -51 25 -78 32 -146 35 -54 3 -98 -1 -125 -10z"/>
      <path d="M3360 1193 c-98 -34 -185 -118 -220 -210 -22 -58 -26 -136 -10 -178
      16 -42 -26 -101 -117 -163 -41 -29 -46 -28 -92 13 -51 46 -72 92 -82 181 -4
      40 -15 86 -23 102 -12 23 -141 132 -304 255 -9 7 -17 6 -25 -2 -9 -10 -3 -20
      28 -49 22 -20 89 -82 149 -139 103 -97 108 -105 93 -121 -16 -15 -26 -7 -162
      128 -115 115 -147 142 -157 132 -10 -10 17 -42 132 -157 126 -127 142 -146
      130 -160 -12 -14 -30 0 -160 130 -99 99 -150 143 -155 135 -5 -8 43 -63 136
      -156 138 -138 143 -144 125 -161 -17 -17 -23 -12 -160 131 -114 119 -144 146
      -153 135 -9 -11 14 -46 107 -164 125 -157 158 -185 222 -185 77 0 178 -50 215
      -106 l16 -26 -221 -221 c-196 -196 -222 -226 -222 -254 0 -35 38 -73 73 -73
      14 0 95 74 237 215 118 118 220 215 225 215 6 0 95 -88 200 -196 179 -186 233
      -234 263 -234 21 0 62 47 62 72 0 15 -66 88 -225 248 l-226 226 29 40 c37 51
      98 104 120 104 9 0 37 -5 61 -11 86 -19 226 38 290 120 101 128 105 260 11
      345 -61 55 -129 68 -210 39z m-45 -119 c-51 -51 -115 -156 -115 -191 0 -6 -5
      -15 -12 -19 -18 -11 -3 97 20 142 11 22 31 49 44 61 27 26 110 74 117 68 2 -3
      -22 -30 -54 -61z"/>
      <path d="M110 1170 c-60 -85 -101 -300 -110 -582 0 -3 15 -24 33 -46 l34 -40
      -5 -224 c-5 -247 -2 -268 46 -268 16 0 33 5 40 12 9 9 12 133 13 493 l0 480
      20 -55 c21 -60 126 -277 156 -323 12 -19 35 -35 68 -45 42 -14 53 -24 69 -57
      10 -22 28 -59 39 -82 l21 -41 -52 -90 c-57 -99 -62 -124 -31 -146 33 -23 60
      -6 100 63 20 35 39 62 43 62 4 0 25 -27 47 -61 43 -68 59 -78 95 -62 36 17 30
      50 -28 147 -51 86 -51 87 -36 118 9 18 22 49 28 69 13 42 47 74 89 83 16 3 36
      14 44 23 25 28 134 254 161 333 l25 74 1 -485 c0 -364 3 -489 12 -498 7 -7 24
      -12 40 -12 48 0 51 21 46 268 l-5 224 34 40 c18 22 33 43 33 46 -9 282 -50
      497 -110 582 -45 63 -48 59 -266 -316 -110 -190 -205 -346 -210 -346 -4 0
      -102 154 -216 343 -144 240 -213 345 -227 347 -12 2 -27 -8 -41 -28z"/>
    </g>
  </svg>  -->
 
<svg version="1.1" width="364.000000pt" height="122.000000pt" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 162 35" style="enable-background:new 0 0 162 35;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#F8B133;}
	.st1{fill:#F29100;}
	.st2{font-family:'MyriadPro-Regular';}
	.st3{font-size:4.9336px;}
</style>
<g>
	<path class="st0" d="M38.2,20c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8V7.8
		c0-0.3,0.1-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3h6.5c0.3,0,0.5,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.1,0.5-0.3,0.8
		c-0.2,0.2-0.5,0.3-0.8,0.3h-5.4v3.5h4.6c0.3,0,0.5,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.1,0.5-0.3,0.8
		c-0.2,0.2-0.5,0.3-0.8,0.3h-4.6v4.6C38.5,19.5,38.4,19.8,38.2,20z"/>
	<path class="st0" d="M49.6,18.2c-1.3-1.4-2-3-2-4.9c0-1.9,0.7-3.6,2-4.9c1.3-1.4,2.9-2.1,4.8-2.1c1.9,0,3.5,0.7,4.8,2.1
		c1.3,1.4,2,3,2,4.9c0,1.9-0.7,3.5-2,4.9c-0.6,0.6-1.3,1.2-2.1,1.5c-0.8,0.4-1.7,0.6-2.6,0.6C52.5,20.3,50.9,19.6,49.6,18.2z
		 M49.9,13.3c0,1.3,0.4,2.4,1.3,3.4c0.9,0.9,1.9,1.4,3.2,1.4s2.3-0.5,3.2-1.4c0.9-0.9,1.3-2.1,1.3-3.3c0-1.3-0.4-2.4-1.3-3.3
		c-0.9-0.9-1.9-1.4-3.2-1.4c-1.3,0-2.3,0.5-3.2,1.4C50.3,10.9,49.9,12,49.9,13.3z"/>
	<path class="st0" d="M66.4,18.2c-1.3-1.4-2-3-2-4.9c0-1.9,0.7-3.6,2-4.9c1.3-1.4,2.9-2.1,4.8-2.1c1.9,0,3.5,0.7,4.8,2.1
		c1.3,1.4,2,3,2,4.9c0,1.9-0.7,3.5-2,4.9c-0.6,0.6-1.3,1.2-2.1,1.5c-0.8,0.4-1.7,0.6-2.6,0.6C69.3,20.3,67.7,19.6,66.4,18.2z
		 M66.7,13.3c0,1.3,0.4,2.4,1.3,3.4c0.9,0.9,1.9,1.4,3.2,1.4c1.2,0,2.3-0.5,3.2-1.4c0.9-0.9,1.3-2.1,1.3-3.3c0-1.3-0.4-2.4-1.3-3.3
		c-0.9-0.9-1.9-1.4-3.2-1.4S68.9,9,68,9.9C67.1,10.9,66.7,12,66.7,13.3z"/>
	<path class="st0" d="M81.9,19.2V7.8c0-0.3,0.1-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3h2.3c1.9,0,3.5,0.7,4.9,2c1.3,1.3,2,2.9,2,4.8
		c0,1.9-0.7,3.5-2,4.8c-1.3,1.3-3,2-4.9,2h-2.3c-0.3,0-0.6-0.1-0.8-0.3C82,19.8,81.9,19.5,81.9,19.2z M84,8.9v9.3h1.2
		c1.3,0,2.4-0.5,3.3-1.4c0.9-0.9,1.4-2,1.4-3.3c0-1.3-0.5-2.4-1.4-3.3c-0.9-0.9-2-1.4-3.3-1.4H84z"/>
	<path class="st0" d="M103.7,7.9c0.8,0.8,1.2,1.8,1.2,2.9s-0.4,2.1-1.2,2.9c-0.8,0.8-1.8,1.2-3,1.2h-2.4v4.2c0,0.3-0.1,0.5-0.3,0.8
		c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8V7.8c0-0.3,0.1-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3
		h3.5C101.9,6.7,102.9,7.1,103.7,7.9z M98.3,8.9v4h2.4c0.6,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4c0-0.6-0.2-1-0.6-1.4
		c-0.4-0.4-0.9-0.6-1.4-0.6H98.3z"/>
	<path class="st0" d="M108.9,18.2c-1.3-1.4-2-3-2-4.9c0-1.9,0.7-3.6,2-4.9c1.3-1.4,2.9-2.1,4.8-2.1c1.9,0,3.5,0.7,4.8,2.1
		c1.3,1.4,2,3,2,4.9c0,1.9-0.7,3.5-2,4.9c-0.6,0.6-1.3,1.2-2.1,1.5c-0.8,0.4-1.7,0.6-2.6,0.6C111.9,20.3,110.3,19.6,108.9,18.2z
		 M109.2,13.3c0,1.3,0.4,2.4,1.3,3.4c0.9,0.9,1.9,1.4,3.2,1.4c1.2,0,2.3-0.5,3.2-1.4c0.9-0.9,1.3-2.1,1.3-3.3c0-1.3-0.4-2.4-1.3-3.3
		c-0.9-0.9-1.9-1.4-3.2-1.4c-1.3,0-2.3,0.5-3.2,1.4C109.7,10.9,109.2,12,109.2,13.3z"/>
	<path class="st0" d="M124.5,7.8c0-0.3,0.1-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8
		v11.4c0,0.3-0.1,0.5-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8V7.8z"/>
	<path class="st0" d="M132.6,11.2v8c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3
		c-0.2-0.2-0.3-0.5-0.3-0.8V7.9c0-0.4,0.1-0.7,0.4-0.9c0.3-0.2,0.6-0.3,0.9-0.2c0.3,0,0.5,0.2,0.7,0.4l6.4,8.7v-8
		c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.5,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.7v11.3c0,0.3-0.1,0.5-0.3,0.8
		c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.4c-0.1-0.1-0.1-0.1-0.1-0.1L132.6,11.2z"/>
	<path class="st0" d="M154.9,7c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3H151v10.3
		c0,0.3-0.1,0.5-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3s-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8V8.9h-3.1c-0.3,0-0.6-0.1-0.8-0.3
		c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3h8.4C154.4,6.7,154.7,6.8,154.9,7z"/>
</g>
<g>
	<ellipse class="st1" cx="17.4" cy="17.5" rx="4.4" ry="4.3"/>
	<path class="st0" d="M23.5,23.4c-1.2,1.2-1.8,2.8-1.5,4.1c-1.4,0.6-2.8,0.9-4.4,0.9H6.6V17.5c0-2.9,1.1-5.5,3-7.4
		c0.8,0.4,1.7,0.5,2.6,0.2l4.6-1.9C17,8.2,17.1,7.9,17,7.6l-0.1-0.1c-0.1-0.3-0.5-0.4-0.8-0.3L13,8.5c-0.2,0.1-0.5,0-0.6-0.2l0,0
		c-0.1-0.2,0-0.5,0.2-0.6l3.2-1.3c0.3-0.1,0.4-0.5,0.3-0.8c-0.1-0.3-0.5-0.5-0.9-0.3l-3.1,1.3c-0.2,0.1-0.5,0-0.6-0.2l0,0
		c-0.1-0.2,0-0.5,0.2-0.6l3.1-1.3c0.3-0.1,0.5-0.5,0.3-0.8l0,0c-0.1-0.3-0.5-0.5-0.9-0.3L9.9,5.1c-1.2,0.5-1.9,1.8-1.7,3
		c-2.5,2.4-4,5.8-4,9.4v13.3h13.6c2.2,0,4.3-0.5,6.2-1.5c1.3,0.4,3.1-0.1,4.4-1.4c1.7-1.7,2.1-4.1,0.8-5.3
		C27.7,21.3,25.2,21.7,23.5,23.4z"/>
	<path class="st0" d="M17.7,4.2c-0.3,0-0.6,0-0.9,0c0.4,0.5,0.6,1.2,0.6,1.9c0,0.2,0,0.3,0,0.5c0.1,0,0.2,0,0.3,0
		c6.1,0,11.1,4.9,11.1,10.9c0,1.2-0.2,2.3-0.6,3.4c0.9,0.2,1.6,0.7,2.1,1.4c0.6-1.5,0.9-3.1,0.9-4.8C31.2,10.2,25.1,4.2,17.7,4.2z"
		/>
</g>
<text transform="matrix(1 0 0 1 114.9848 28.3238)" class="st1 st2 st3">By</text>
<g>
	<path class="st1" d="M125.9,28.4c0-0.9-0.1-1.9,0-2.8c0.3-0.5,0.6-1,0.9-1.4c0.8-0.2,1.5-0.3,2.3-0.4c0.1,0.1,0.2,0.1,0.3,0.1
		c0.1,0,0.3-0.1,0.3-0.2c0.7-0.1,1.4-0.1,2.1-0.1c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.3-0.1,0.3-0.3c0,0,0-0.1,0-0.1l0,0
		c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.6,0-1.3,0.1-2.1,0.2c-0.1-0.1-0.2-0.2-0.3-0.2
		c-0.2,0-0.3,0.1-0.3,0.2c-0.6,0.1-1.3,0.2-2,0.4c0.6-0.9,1.3-1.8,2-2.6c0,0,0,0,0.1,0c0.2,0,0.3-0.2,0.3-0.3c0-0.2-0.2-0.3-0.3-0.3
		c-0.2,0-0.3,0.2-0.3,0.3c0,0,0,0.1,0,0.1c-0.6,0.7-1.4,1.7-2.2,2.9c-0.2,0.1-0.4,0.1-0.6,0.2c0.1-1.3,0.2-2.6,0.5-3.8
		c-0.4,1.3-0.6,2.6-0.7,3.9c-0.6,0.2-1.1,0.4-1.7,0.7c-0.3-0.6-0.5-1.2-0.7-1.8c0-0.1,0.1-0.1,0.1-0.2c0-0.2-0.2-0.3-0.3-0.3
		s-0.3,0.2-0.3,0.3c0,0.2,0.1,0.3,0.3,0.3c0.2,0.5,0.5,1.1,0.8,1.8c-0.4,0.2-0.8,0.4-1.1,0.6c0.4-0.2,0.8-0.4,1.2-0.5
		c0.3,0.6,0.6,1.3,1,1.9c-0.3,0.6-0.6,1.3-0.8,1.9c-0.5-0.2-1-0.4-1.4-0.6c0.4,0.3,0.9,0.6,1.4,0.8c-0.2,0.5-0.3,1-0.4,1.5
		c0.2-0.5,0.3-0.9,0.5-1.4c0.5,0.2,1.1,0.5,1.6,0.6c0.1,1.2,0.2,2.3,0.4,3.2c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.2,0.2,0.3,0.3,0.3
		s0.3-0.2,0.3-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.2-1-0.3-2-0.4-3.1c0.2,0.1,0.5,0.1,0.7,0.2c0.7,1,1.6,2,2.5,2.8
		c-0.8-0.8-1.5-1.7-2.1-2.7c0.3,0.1,0.6,0.1,0.9,0.2c0,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.3-0.1c1.4,0.2,2.7,0.3,3.6,0.3
		c0.1,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c-1.2,0-2.3-0.1-3.5-0.3c0-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.2,0.1-0.3,0.1
		c-0.4-0.1-0.8-0.2-1.2-0.3C126.5,29.4,126.2,28.9,125.9,28.4z M125.6,27.8c-0.2-0.3-0.3-0.5-0.5-0.8c0.1-0.3,0.3-0.6,0.5-0.9
		C125.5,26.7,125.5,27.2,125.6,27.8z M126.3,24.2c-0.1,0.2-0.3,0.4-0.4,0.7c0-0.2,0-0.4,0-0.6C126,24.3,126.2,24.3,126.3,24.2z
		 M124.1,25c0.5-0.2,1-0.4,1.6-0.5c0,0.3,0,0.7-0.1,1c-0.2,0.4-0.4,0.8-0.6,1.2C124.6,26.1,124.3,25.5,124.1,25z M124.1,29
		c0.2-0.6,0.5-1.1,0.8-1.7c0.2,0.4,0.4,0.7,0.7,1.1c0,0.4,0,0.7,0.1,1.1C125.1,29.4,124.6,29.2,124.1,29z M126,29.6
		c0-0.2,0-0.4,0-0.6c0.2,0.3,0.3,0.5,0.5,0.8C126.3,29.7,126.1,29.7,126,29.6z M126.8,31.3c0,0.1-0.1,0.2-0.2,0.2
		c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2C126.7,31.1,126.8,31.2,126.8,31.3z M125.1,25.4c0,0.1-0.1,0.2-0.2,0.2
		c-0.1,0-0.2-0.1-0.2-0.2s0.1-0.2,0.2-0.2C125,25.2,125.1,25.3,125.1,25.4z M125.1,28.7c0,0.1-0.1,0.2-0.2,0.2
		c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2C125,28.5,125.1,28.6,125.1,28.7z M123.3,27.3c-0.2,0-0.3-0.2-0.3-0.3
		c0-0.2,0.2-0.3,0.3-0.3s0.3,0.2,0.3,0.3C123.6,27.1,123.5,27.3,123.3,27.3z M124,23.3c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		c0,0.1-0.1,0.2-0.2,0.2C124.1,23.5,124,23.4,124,23.3z M126.4,23c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		c0,0.1-0.1,0.2-0.2,0.2C126.5,23.2,126.4,23.1,126.4,23z M125.1,31.6c0,0.1-0.1,0.1-0.1,0.1s-0.1-0.1-0.1-0.1s0.1-0.1,0.1-0.1
		S125.1,31.5,125.1,31.6z M131.3,32c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
		C131.2,31.8,131.3,31.9,131.3,32z M128.8,25h2.3v0.7h-1.5v0.7h1.4V27h-1.4v1.3h-0.7V25z M132,26h0.7v0.4h0c0.1-0.1,0.2-0.2,0.3-0.3
		c0.1-0.1,0.2-0.1,0.4-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0v0.7c-0.1,0-0.1,0-0.1,0c0,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0.1
		c-0.1,0-0.1,0.1-0.2,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0,0.3v1H132V26z M135.9,28L135.9,28c-0.1,0.1-0.2,0.2-0.3,0.3
		c-0.1,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.3
		c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0
		c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.2l-0.4-0.4c0.1-0.1,0.3-0.2,0.5-0.3
		c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0,0.3,0,0.5v1.2h-0.7V28z
		 M135.7,27.3c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0.1,0.2
		c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0-0.2v-0.2H135.7z M139,26.8
		c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.2,0,0.3,0.1,0.4
		c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.1l0.5,0.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.3,0.1-0.4,0.1
		c-0.2,0-0.3,0-0.5-0.1c-0.2-0.1-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.2,0-0.3,0.1-0.5
		c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.2-0.1,0.3-0.1,0.5-0.1c0.1,0,0.3,0,0.4,0.1c0.2,0,0.3,0.1,0.4,0.2L139,26.8z
		 M141.8,26.6h-0.6v0.8c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0
		c0.1,0,0.1,0,0.1-0.1v0.6c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.3-0.1
		c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.3v-1.1H140V26h0.5v-0.7h0.7V26h0.6V26.6z M144.1,28L144.1,28
		c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.1-0.1-0.2-0.2
		c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1
		c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.2
		l-0.4-0.4c0.1-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.4
		s0,0.3,0,0.5v1.2h-0.7V28z M143.9,27.3c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.2
		c0,0.1,0,0.1,0.1,0.2c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0-0.2v-0.2
		H143.9z M145.7,24.7h0.7v3.6h-0.7V24.7z M147.3,25.3c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1
		c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3-0.1C147.3,25.5,147.3,25.4,147.3,25.3z
		 M147.4,26h0.7v2.3h-0.7V26z M150.6,26.6H150v0.8c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.2,0
		c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1-0.1v0.6c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.3-0.1
		c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.3v-1.1h-0.5V26h0.5v-0.7h0.7V26h0.6V26.6z M153.7,27.9c-0.1,0.1-0.3,0.3-0.4,0.3
		c-0.2,0.1-0.4,0.1-0.5,0.1c-0.2,0-0.3,0-0.5-0.1c-0.2-0.1-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.1-0.1-0.3-0.1-0.5
		c0-0.2,0-0.3,0.1-0.5c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.2-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.4,0.1
		c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.2,0.4c0.1,0.1,0.1,0.3,0.1,0.5v0.2h-1.7c0,0.1,0.1,0.2,0.2,0.3
		c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.1,0.2-0.2L153.7,27.9z M153.1,26.9c0-0.1,0-0.2-0.1-0.3
		c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0,0.2H153.1z"/>
</g>
</svg>

</div>


 




