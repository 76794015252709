<mat-toolbar id="main-toolbar" class="mat-elevation-z2">
  <div class="theme-container" fxLayout="row" fxLayoutAlign="space-between center">
    <span fxHide="false" fxHide.gt-sm>
      <button mat-icon-button (click)="sidenavToggle()">
        <mat-icon class="mat-icon-lg">menu</mat-icon>
      </button>
    </span>
    <a class="logo" routerLink="/">
      <app-logo></app-logo>
    </a>
    <div fxShow="false" fxShow.gt-sm class="horizontal-menu">
      <app-horizontal-menu [menuParentId]="0"></app-horizontal-menu>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <button (click)="reservation()" mat-icon-button [matTooltip]="'RESERVATION' | translate" fxShow="false"
        fxShow.gt-xs="true">
        <mat-icon>book_online</mat-icon>
      </button>
      <a routerLink="/account/favorites" mat-icon-button matTooltip="Favorites" class="mx-2" fxShow="false"
        fxShow.gt-xs="true">
        <mat-icon [matBadge]="appService.Data.favorites.length" [matBadgeHidden]="appService.Data.favorites.length == 0"
          matBadgeSize="small" matBadgeColor="warn">favorite_border</mat-icon>
      </a>
      <button (click)="openCart()" mat-icon-button matTooltip="Cart list">
        <mat-icon [matBadge]="appService.Data.totalCartCount" [matBadgeHidden]="appService.Data.totalCartCount == 0"
          matBadgeSize="small" matBadgeColor="warn">shopping_cart</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>